@use "sass:map";
@use '@portal/ui-kit';
@use './variables';
@use "@portal/wen-widget";
@use "@portal/wen-components";

/* Color config */
$color-primary-map: map.merge(
  ui-kit.$portal-red,
  (
    700:  var(--wen-primary-color)
  )
);
$color-secondary-map: map.merge(
  ui-kit.$portal-light-green,
  (
    700: var(--wen-secondary-color)
  )
);
$app-primary: ui-kit.define-palette($color-primary-map);
$app-accent: ui-kit.define-palette($color-secondary-map);

/* Font config */
$typography: ui-kit.define-typography-config(
  /* Font style */ $font-family: #{$font},
  /* Display 1 */ $display-1: ui-kit.define-typography-level(24px, 21px, 600),
  /* Display 2 */ $display-2: ui-kit.define-typography-level(24px, 21px, 400),
  /* H1 */ $headline: ui-kit.define-typography-level(20px, 21px, 600),
  /* H2 */ $title: ui-kit.define-typography-level(16px, 21px, 600),
  /* H3 */ $subheading-1: ui-kit.define-typography-level(13px, 17px, 400),
  /* H4 */ $subheading-2: ui-kit.define-typography-level(11px, 15px, 600),
  /* Body */ $body-1: ui-kit.define-typography-level(16px, 24px, 400),
  /* Subtitle */ $body-2: ui-kit.define-typography-level(16px, 21px, 400),
  /* Buttons */ $button: ui-kit.define-typography-level(18px, 24px, 600),
  /* Caption*/ $caption: ui-kit.define-typography-level(11px, 15px, 400)
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include ui-kit.core($typography, $typography);`
@include ui-kit.core($typography, $typography);
// @include mat.legacy-core();

$wen-light-theme: wen-widget.define-light-theme($app-primary, $app-accent);
@include ui-kit.all-component-themes($wen-light-theme);
@include wen-widget.all-themes($wen-light-theme, $typography);
@include wen-components.all-themes($wen-light-theme, $typography);
